import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const FileDisplay = () => {
    const { id_enlace, filename } = useParams()
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        // Construct the URL for the file from the backend
        const filePath = `${API_URL}/files/${id_enlace}/${filename}`;
        setFileUrl(filePath);
    }, [id_enlace, filename]);

    // Conditionally render based on file type (e.g., image or PDF)
    const renderFile = () => {
        if (filename?.endsWith('.pdf')) {
            return (
                <embed
                    src={fileUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            );
        } else if (filename?.endsWith('.jpg') || filename?.endsWith('.png')) {
            return <img src={fileUrl} alt={filename} style={{ maxWidth: '100%' }} />;
        } else {
            return <p>File type not supported for direct display.</p>;
        }
    };

    return (
        <div>
            <h2>Displaying File: {filename}</h2>
            {fileUrl && renderFile()}
        </div>
    );
};

export default FileDisplay;
