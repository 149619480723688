import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

function Footer() {

    return (
        <Container fluid className='footer'>
            <Row className='pt-5 justify-content-md-end'>
                <Col md={2}><strong>SITIOS DE INTERES</strong></Col>
                <Col md={2}></Col>
                <Col md={2}></Col>
            </Row>
            <Row className='pt-4 pb-3 justify-content-md-end'>
                <Col md={2}>
                    <ul className='custom-list'>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/transparencia/'>TRANSPARENCIA</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Contraloria_Social'>CONTRALORÍA SOCIAL</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Integridad_Publica_Comite_de_Etica'>INTEGRIDAD PÚBLICA/COMITÉ DE ÉTICA</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Consejo_Social'>CONSEJO SOCIAL</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Consejo_de_Calidad'>CONSEJO DE CALIDAD</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Comite_de_Tecnologia_de_Informacion_y_Comunicaciones'>COMITÉ TIC</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Buzon_de_Quejas_y_Sugerencias'>BUZÓN</Nav.Link></li>
                    </ul>
                </Col>
                <Col md={2}>
                    <ul className='custom-list'>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/servicios#Centro_LABVIEW'>CENTRO LABVIEW</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/servicios#Toefl_ITP'>TOEFL ITP</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/servicios#Toefl_IBT'>TOEFL IBT</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/redconocer/'>RED CONOCER</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Bolsa_de_Trabajo_Academico'>BOLSA DE TRABAJO ACADÉMICO</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/Convocatoria_de_Oposicion'>CONVOCATORIA OPOSICIÓN ACADÉMICA</Nav.Link></li>
                    </ul>
                </Col>
                <Col md={2}>
                    <ul className='custom-list'>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://platinum.upchiapas.edu.mx/siu'>PLATINUM</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/registro_empresa/'>SOLICITUD ESTANCIAS</Nav.Link></li>
                        <li><i className="bi bi-dot"></i><Nav.Link className='footerLink' href='https://2023.upchiapas.edu.mx/vinculacion#Seguimiento_de_Egresados'>SEGUIMIENTO DE EGRESADOS</Nav.Link></li>
                    </ul>
                </Col >
            </Row >
            <Row className='pt-5 pb-5 justify-content-md-end'>
                <Col md={6} className='footerUPCH'>
                    UNIVERSIDAD POLITÉCNICA DE CHIAPAS<br />
                    Carretera Tuxtla Gutiérrez - Portillo Zaragoza Km 21+500<br />
                    Colonia Las Brisas; CP. 29150<br />
                    Teléfono: 01961 61 71460<br />
                    Suchiapa, Chiapas<br />
                </Col>
            </Row>
            <Row className='justify-content-md-end pb-5'>
                <Col md={6}>
                    <Row xs='auto' className='justify-content-md-center'>
                        <Col className='text-center footerUPCHNav'><Nav.Link href='https://2023.upchiapas.edu.mx/Aviso_de_Privacidad'>AVISOS DE PRIVACIDAD</Nav.Link></Col><Col md={1} className='text-center'><div className="vr"></div></Col>
                        <Col className='text-center footerUPCHNav'><Nav.Link href='/Derechos_de_Autor'>DERECHOS DE AUTOR</Nav.Link></Col>
                    </Row>
                </Col>
            </Row>
        </Container >
    );
}

export default Footer;