import React from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap-icons/font/bootstrap-icons.css';

function WelcomeComponent() {

    return(
        <Container className='welcome pt-5 pb-5' fluid>
            <h1 className='pt-5 pb-2'>GENERANDO TECNOLOGÍA PARA EL BIEN COMÚN</h1>
            <Container className='welcomeText pt-2 pb-5'>El futuro que convive ahora con nosotros exige ciudadanos comprometidos con el colectivo social, formados sólidamente para actuar en el contexto chiapaneco desde la comunidad y con ella, generar procesos productivos que den respuesta a las diversas necesidades sociales, al mismo tiempo de preservar el medio ambiente y procurar la regeneración de grandes recursos naturales destruidos</Container>
        </Container>
    )

}

export default WelcomeComponent;