import React from "react";
import Slider from "./Slider/Slider";
import axios from "axios";
import { SliderItem } from "../Interfaces/Interfaces";
import { Row, Spinner } from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;

const MainSlider = () => {
    const [items, setItems] = React.useState<SliderItem[] | null>(null);

    const getItems = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/slider`)
            const result = response.data;
            setItems(result);
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    React.useEffect(() => {
        getItems();
    },[])

    return (
        <>
            {
                items ? <Slider items={items} />
                    :
                    <Row className="justify-content-center m-2">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Row>
            }
        </>
    )
}

export default MainSlider;