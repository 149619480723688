import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { SliderItem } from '../../Interfaces/Interfaces';

interface SliderProps {
    items: SliderItem[];  // Cambia aquí el nombre de la interfaz a SliderProps
}

const Slider: React.FC<SliderProps> = ({ items }) => {
    return (
        <Carousel>
            {
                items.map((item: SliderItem, index: number) => (
                    (item.url !== "") ?
                        <Carousel.Item
                            key={index}
                            as="a"
                            href={item.url}>
                            <img
                                className="d-block w-100"
                                src={`https://api.upchiapas.edu.mx/slider/${item.imagen}`}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        :
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={`https://api.upchiapas.edu.mx/slider/${item.imagen}`}
                                alt="Second slide"
                            />
                        </Carousel.Item>

                )
                )
            }
            {/*                 <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="https://www.upchiapas.edu.mx/images/slider/bannerpicnichweb.jpg"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="https://www.upchiapas.edu.mx/images/slider/bannerweb_VISITA.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item> */}
        </Carousel>

    );
}

export default Slider;