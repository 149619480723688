import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import './SidePanel.css';

interface SidePanelProps{
    titulo: string;
    contenido: string;
}

const SidePanel: React.FC<SidePanelProps>= ({titulo, contenido}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) {
      const timer = window.setTimeout(() => {
        handleClose(); // Cierra el panel después de 10 segundos
      }, 3000); // 10000 ms = 10 segundos

      // Limpia el temporizador si el componente se desmonta antes de que expire
      return () => window.clearTimeout(timer); // Usa window.clearTimeout
    }
  }, []); // Solo se ejecuta cuando `show` cambia
  

  return (
    <>
      {/* Botón flotante con la flechita */}
      <div className="floating-tab" onClick={show ? handleClose : handleShow}>
        <span className={show ? "bi bi-arrow-right" : "bi bi-arrow-left"}></span> {/* Aquí puedes cambiar el contenido del icono o usar un SVG */}
      </div>

      {/* Componente Offcanvas que se abrirá con el clic en la flechita */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><h1>{titulo}</h1></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div dangerouslySetInnerHTML = {{__html: contenido}} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default SidePanel;
