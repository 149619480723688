import axios from "axios";
import { FC, useEffect, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import { RowsPhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "react-photo-album/rows.css";
import { Row } from "react-bootstrap";
interface ItemProps {
    id_enlace: number
}

interface Photo {
    src: string;
    width: number;
    height: number;
}

const API_URL = process.env.REACT_APP_API_URL;

const ImageGallery: FC<ItemProps> = ({ id_enlace }) => {

    const [images, setImages] = useState<Photo[]>([]);;
    const [index, setIndex] = useState(-1);

    const getImages = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/images/${id_enlace}`)
            const items = response.data;
            setImages(items.images);
        } catch (error) {
            //console.log('Error: ', error)
        }
    }

    useEffect(() => {
        getImages();
    }, [])

    return (
        <Row className="pb-5">
            <RowsPhotoAlbum
                photos={images}
                targetRowHeight={150}
                onClick={({ index: current }) => setIndex(current)}
            />

            <Lightbox
                index={index}
                slides={images}
                open={index >= 0}
                close={() => setIndex(-1)}
            />
        </Row>
    )
}

export default ImageGallery