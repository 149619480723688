import MainMenu from "./MainMenu";
import NavBarIndex from "./NavBar";

function HeaderWrapper() {
    return (
        <>
            <NavBarIndex />
            <MainMenu />
        </>
    )
}

export default HeaderWrapper;
