import React from 'react';
import Container from 'react-bootstrap/Container';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ExpComponent() {

    return (
        <Container className='pt-5 pb-5 mx-auto' fluid>
            <Row>
                <Col md={12}>
                    <div className='d-flex justify-content-center'>
                        <h1 className='pt-5 pb-5'>EXPERIENCIA TECNOLÓGICA</h1>
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={3}>
                    <a href='https://2023.upchiapas.edu.mx/oferta_educativa/' className='no-style'>
                        <Card className='cardItemPurple gris1 mt-2'>
                            <Card.Img variant="top" src="images/1.png" />
                            <Card.Body className='bgColorLeft'>
                                <Card.Title className='pt-2'>INGENIERÍAS</Card.Title>
                            </Card.Body>
                        </Card>
                    </a>
                </Col>
                <Col md={3}>
                    <Link to='/Maestrias' className='no-style'>
                        <Card className='cardItemPurple gris3 mt-2'>
                            <Card.Img variant="top" src="images/2.png" />
                            <Card.Body className='bgColorLeft'>
                                <Card.Title className='pt-2'>MAESTRÍA</Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
                <Col md={3}>
                    <a href='https://di.upchiapas.edu.mx/' className='no-style'>
                        <Card className='cardItemPurple gris4 mt-2'>
                            <Card.Img variant="top" src="images/3.png" />
                            <Card.Body className='bgColorLeft'>
                                <Card.Title className='pt-2'>DOCTORADO</Card.Title>
                            </Card.Body>
                        </Card>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default ExpComponent;