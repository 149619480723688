import React, { useEffect, useState } from 'react';

const GoUpButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Toggle visibility based on scroll position
    const toggleVisibility = () => {
        if (window.scrollY > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to the top of the page when the button is clicked
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div>
            {isVisible && (
                <button className="go-up-button" onClick={scrollToTop}>
                <i className="bi bi-arrow-up-circle"></i> {/* Bootstrap icon */}
            </button>
            )}
        </div>
    );
};

export default GoUpButton;
