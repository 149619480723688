import React from 'react';
import NewsMosaic from './Mosaic/Mosaic';
import axios from 'axios';

interface NewsItem {
  fecha_pub: string,
  img_principal: string,
  cuerpo:  string,
  titulo: string,
  url: string,
  id_enlace: number
}

const API_URL = process.env.REACT_APP_API_URL;

const NoticiasWrapper = () => {
  const [newsData, setNewsData] = React.useState<NewsItem[]>([])

  const getInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/enlaces/noticias`)
      const items = response.data;
      setNewsData(items);
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  React.useEffect(() => {
    getInfo();
  }, []);

  return (
    <NewsMosaic newsItems={newsData} />
  )
}

export default NoticiasWrapper;
