import React from "react";
import HeaderWrapper from "./HeaderWrapper";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";

const NotFound = () => {

    return (
        <>
            <HeaderWrapper />
            <Container fluid>
                <Row>
                    <Col className='menuBox' md={3} >
                        <h1>404</h1>
                    </Col>
                    <Col className='content-wrapper' md={6}>
                        <div className='content-wrapper'>
                            <h1> PÁGINA NO ENCONTRADA</h1><br /><br /><br />
                            <h3>La página que intentas acceder no está disponible</h3>
                        </div>
                    </Col>
                    <Col md={3} className='rightSideBar'></Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default NotFound;