import React, { useEffect, useState, useCallback } from 'react';
import './NewsMosaic.css';
import { Col, Container, OverlayTrigger, Row, Tooltip, TooltipProps } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface NewsItem {
  fecha_pub: string;
  img_principal: string;
  cuerpo: string;
  titulo: string;
  url: string;
  id_enlace: number;
}

interface NewsMosaicProps {
  newsItems: NewsItem[];
}

const NewsMosaic: React.FC<NewsMosaicProps> = ({ newsItems }) => {
  const [{ title, date, activeIndex }, setActiveData] = useState({ title: '', date: '', activeIndex: -1 });
  const [visibleItems, setVisibleItems] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setVisibleItems(width < 768 ? 3 : width < 1195 ? 4 : 6);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toISOString().split('T')[0];
  };

  const showTitle = (tituloS: string, fechaS: string, index: number) => {
    setActiveData({
      title: tituloS,
      date: fechaS !== '\b' ? formatDate(fechaS) : '',
      activeIndex: index, // Guardamos el índice del tooltip activo
    });
  };

  const hideTitle = () => {
    setActiveData({
      title: '',
      date: '',
      activeIndex: -1, // Restablecemos el índice del tooltip
    });
  };

  const renderTooltip = useCallback(
    (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
      <Tooltip id="button-tooltip" {...props}>
        {title}
      </Tooltip>
    ),
    [title]
  );

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center">
            <h1 className="pt-5 pb-5">NOTICIAS</h1>
          </div>
        </Col>
      </Row>
      <Row>
        {newsItems.slice(0, visibleItems).map(({ titulo, fecha_pub, img_principal, url, id_enlace }, index) => (
          <Col xs={12} sm={6} md={3} lg={visibleItems === 4 ? 3 : 2} key={index} className="mb-1">
            <OverlayTrigger
              placement="top"
              show={activeIndex === index} // Solo mostrar el tooltip del elemento activo
              overlay={renderTooltip}
            >
              <Link to={`/${url}`} className="no-style">
                <div
                  onMouseEnter={() => showTitle(titulo, fecha_pub, index)}
                  onMouseLeave={hideTitle} // Ocultar al salir del área
                  className="news-item"
                  style={{ backgroundImage: `url(https://api.upchiapas.edu.mx/images/${id_enlace}/${img_principal})` }}
                >
                  <div className="news-content">
                    <h5>{titulo.substring(0,64)}...</h5>
                  </div>
                </div>
              </Link>
            </OverlayTrigger>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NewsMosaic;
