import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function NavBarIndex() {
    const [activado, setActivado] = useState(false);

    const checkAspirante = async () => {
        try {
            const response = await axios.get(`${API_URL}/aspirante/check`)
            const items = response.data
            if (items.isActive) {
                setActivado(true)
            }
        } catch (error) {
            console.log('Error: there was an error')
        }
    }

    useEffect(() => {
        checkAspirante();
    }, [activado]);

    return (
        <>
            <Navbar expand="xl" className="bg-body-tertiary AppHeader" bg='dark' data-bs-theme='dark'>
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src="/images/UPlogoblancoN.png"
                            className="d-inline-block align-top topLogo"
                        />
                    </Navbar.Brand>
                    {activado && <div className='itemAspirante'><Nav.Link href="#" className='middle'>NUEVO INGRESO</Nav.Link></div>}
                    {/* <Col xs={12} md={7} className='colAspirante'>{activado && <Nav.Link href="#home" className='btn btn-lg itemAspirante ms-auto'>ASPIRANTES</Nav.Link>}</Col>
                    <Col className='my-1' xs={12} md={5}> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto TextMenu">
                            <Nav.Link href="https://2023.upchiapas.edu.mx/alumnos" className='itemTopHeader'>Estudiantes</Nav.Link>
                            <Nav.Link href="https://platinum.upchiapas.edu.mx" className='itemTopHeader'>Docentes & Staff</Nav.Link>
                            <Nav.Link href="#link" className='itemTopHeader disabled'>Familia</Nav.Link>
                            <Nav.Link href="https://2023.upchiapas.edu.mx/egresados" className='itemTopHeader'>Egresados</Nav.Link>
                            <Nav.Link href='https://platinum.upchiapas.edu.mx/alumnos' className='pt-1 platinumNav'>PLATINUM</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                    {/* </Col> */}
                </Container>
                <Nav.Link href='https://platinum.upchiapas.edu.mx/alumnos' className='pt-1 platinum'>PLATINUM</Nav.Link>
            </Navbar>
        </>
    );
}

export default NavBarIndex;